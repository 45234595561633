/**
 * Create something like MUI theme?
 *
 * See: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createMuiTheme.js
 * See: https://github.com/styled-components/polished
 * See: https://github.com/styled-components/styled-theming
 *
 */

import { rem, rgba, lighten, getContrast, darken } from 'polished';

export const breakpoints = {
  small: 0,
  smallmedium: 420,
  medium: 640,
  mediumlarge: 820,
  large: 1024,
  xlarge: 1280,
  xxlarge: 1440,
};

export const breakpointClasses = [
  'small',
  'smallmedium',
  'medium',
  'mediumlarge',
  'large',
  'xlarge',
]; // Separate array to be able to define more breakpoints that not currently will be used

function createBreakpoints() {
  const mediaQueries = {};

  // Object.keys(breakpointClasses).forEach((breakpoint) => {
  breakpointClasses.forEach((breakpoint) => {
    mediaQueries[breakpoint] = `(min-width: ${breakpoints[breakpoint]}px)`;
  });

  return mediaQueries;
}

export const colors = {
  primary: '#043673',
  secondary: '#ffdd00',
  third: '#0065a4',
  fourth: '#001641',
  fifth: '#add1ef',
  sixt: '#42593c',
  success: '#42593c',
  warning: '#ffdd00',
  alert: '#721111',
  cta: '#043673',
  alt: '#ffdd00',
  black: '#000',
  white: '#FCFCFC',
  greyLight: '#a3a3a3',
  grey: '#a7a9ac',
  greyDark: '#231f20',
  highlight: '#BDD891',
  lightBlack: '#2f2f2f',
  border: '#5D5D5D',
  borderNegative: '#ffffff',
  fadedGray: rgba('#E9E9E9', 0.5),
  fadedText: '#555555',
  fadedTextNegative: '#B2B2B2',
  backgroundDarken: rgba('#000', 0.4),
};

const buttonColors = {
  primary: colors.primary,
  secondary: colors.secondary,
  third: colors.third,
  success: colors.success,
  warning: colors.warning,
  alert: colors.alert,
  cta: colors.cta,
  ctaAlt: colors.fourth,
  alt: colors.alt,
  black: colors.black,
  white: colors.white,
  grey: colors.grey,
};

const theme = {
  mode: 'light',
  global: {
    radius: '999px',
    menuPadding: '0.6rem 1rem',
    textDirection: 'ltr',
    transition: {
      duration: '0.15s',
      timing: 'ease',
    },
    border: {
      color: colors.grey,
      width: '1px',
      style: 'solid',
    },
  },
  palette: {
    background: {
      body: '#001B40',
      content: colors.white,
      accent: colors.cta,
      dark: '#001B40',
      light: colors.greyLight,
    },
    text: {
      primary: colors.greyDark,
      black: colors.greyDark,
    },
    colors,
    social: {
      facebook: '#3b5998',
      twitter: '#1da1f2',
      pinterest: '#bd081c',
      youtube: '#cd201f',
      instagram: '#e1306c',
      vimeo: '#1ab7ea',
      linkedin: '#0077b5',
      googleplus: '#dd4b39',
      reddit: '#ff4500',
      whatsapp: '#25d366',
    },
    buttons: buttonColors,
  },
  spacing: {
    gutter: rem(20),
    columnGutter: rem(20 / 2),
    // gutter: {
    //   small: rem(20),
    //   large: rem(30),
    // }
  },
  grid: {
    columns: 16,
    maxWidth: rem(1200),
    maxWidthText: rem(820),
    breakpoints: createBreakpoints(),
  },
  typography: {
    global: {
      fontSize: '100%',
      lineHeight: 1.5,
      rendering: 'optimizeLegibility',
    },
    fonts: {
      primary: 'Source Sans Pro, sans-serif',
      heading: 'Playfair Display, sans-serif',
      alt: 'Playfair Display, sans-serif',
      button: 'Source Sans Pro, sans-serif',
    },
    weights: {
      primary: {
        normal: '300',
        bold: '400',
        extrabold: '600',
      },
      alt: {
        normal: '400',
        bold: '700',
      },
      heading: {
        normal: '400',
        bold: '700',
      },
      button: {
        normal: '300',
        bold: '400',
      },
    },
    heading: {
      // lineHeight: 1.32,
      lineHeight: 1.1,
      // marginBottom: '0.7em',
      marginBottom: '0.44em',
      textTransform: 'none',
      headingSizes: {
        small: {
          h1: rem(28),
          h2: rem(28),
          h3: rem(22),
          h4: rem(20),
          h5: rem(18),
          h6: rem(16),
        },
        mediumlarge: {
          h1: rem(30),
          h2: rem(30),
          h3: rem(22),
          h4: rem(20),
          h5: rem(18),
          h6: rem(16),
        },
        large: {
          h1: rem(32),
          h2: rem(32),
          h3: rem(24),
          h4: rem(20),
          h5: rem(18),
          h6: rem(16),
        },
      },
    },
    paragraph: {
      fontSize: rem(17),
      lineHeight: 1.7,
      marginBottom: '1.14rem',
    },
    anchor: {
      default: colors.primary,
      hover: lighten(0.2, colors.primary),
      decoration: 'none',
    },
    button: {
      letterSpacing: '0.01em',
      textTransform: 'none',
      fontWeight: '400',
      buttonSizes: {
        small: rem(13),
        default: rem(16),
        large: rem(18),
      },
    },
  },
  components: {
    button: {
      radius: '999px',
      padding: '0.88rem 2.5em',
      colorMultiplier: 0.1,
    },
    drawer: {
      background: colors.white,
      padding: '0.5rem 1rem',
      item: {
        color: colors.black,
        hover: lighten(0.2, colors.primary),
        padding: '0.6rem 1.15rem',
        fontSize: rem(15.5),
      },
    },
    menu: {
      item: {
        color: colors.greyDark,
        hover: lighten(0, colors.primary),
        colorNegative: colors.white,
        hoverNegative: darken(0.2, colors.white),
        padding: '0.75rem 0.85rem',
        fontSize: rem(16),
        letterSpacing: '0.01em',
        textTransform: 'uppercase',
        fontWeight: '400',
      },
      itemVertical: {
        color: colors.greyDark,
        hover: lighten(0.2, colors.primary),
        colorNegative: colors.white,
        hoverNegative: darken(0.2, colors.white),
        padding: '0.4em 1.15rem',
        fontSize: rem(16),
        letterSpacing: '0.01em',
        textTransform: 'uppercase',
        fontWeight: '400',
      },
    },
    input: {
      radius: '0',
      padding: '0.5rem',
      borderColor: colors.grey,
      backgroundColor: colors.greyLight,
      backgroundColorActive: colors.greyLight,
      color: colors.black,
    },
  },
};

/**
 * Helper method for easier use of breakpoint output in styled-component styles
 */
export const breakpoint = (size) => {
  return `@media ${theme.grid.breakpoints[size]}`;
};

/**
 * Helper method to generate responsive heading sizing from objects by breakpoint
 */
export const headingSizes = (sizes) => {
  let output = '';

  Object.keys(sizes).forEach((breakpointKey) => {
    let breakpointSizes = '';

    Object.keys(sizes[breakpointKey]).forEach((selector) => {
      breakpointSizes += `
        ${selector} {
          font-size: ${sizes[breakpointKey][selector]};
        }\n`;
    });

    output += `${breakpoint(breakpointKey)} {
      ${breakpointSizes}
    }\n`;
  });

  return output;
};

/**
 * Helper method to generate button variants
 */
export const buttonVariants = (variant) => {
  let output = '';

  // Sizes
  Object.keys(theme.typography.button.buttonSizes).forEach((key) => {
    // skip default size
    if (key !== 'default') {
      output += `
        &.${key} {
          font-size: ${theme.typography.button.buttonSizes[key]};
        }\n`;
    }
  });

  // Colors
  if (variant === 'hollow') {
    Object.keys(buttonColors).forEach((color) => {
      const contrastRatio = getContrast(
        buttonColors[color],
        theme.palette.background.content,
      );
      const contrastRatioHover = getContrast(buttonColors[color], '#fff');

      const textColor = contrastRatio > 2.4 ? '#fff' : '#000';
      const textColorHover = contrastRatioHover > 2.4 ? '#fff' : '#000';

      output += `
        &.${color} {
          color: ${textColor};
          border-color: ${buttonColors[color]};

          &:hover {
            background-color: ${buttonColors[color]};
            color: ${textColorHover};
          }
        }\n`;
    });
  } else {
    Object.keys(buttonColors).forEach((color) => {
      const contrastRatio = getContrast(buttonColors[color], '#fff');
      const textColor = contrastRatio > 2.4 ? '#fff' : '#000';

      output += `
        &.${color} {
          background-color: ${buttonColors[color]};
          color: ${textColor};

          &:hover {
            background-color: ${lighten(
              theme.components.button.colorMultiplier,
              buttonColors[color],
            )};
          }
        }\n`;
    });
  }

  return output;
};

export default theme;
