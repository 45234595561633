import { createGlobalStyle } from 'styled-components';
import { rem, lighten } from 'polished';
import { breakpoint } from '../../theme';

export default createGlobalStyle`
  .appstore-badges {
    display: inline-block;

   & .badge-item {
      display: inline-block;
      margin-right: ${rem(8)};

      ${breakpoint('mediumlarge')} {
        margin-right: ${rem(14)};
      }

      ${breakpoint('large')} {
        margin-right: ${rem(22)};
      }

      &:last-child {
        margin-right: 0;
      }
    }
    
   & img {
      height: ${rem(39)};
      width: auto !important;
      max-width: auto !important;

      ${breakpoint('mediumlarge')} {
        height: ${rem(44)};
      }

      ${breakpoint('large')} {
        height: ${rem(55)};
      }
    }
  }

  .usp-list {
    list-style-type: none;
    margin: 0 0 1.5rem;
    padding: 0;
  
    & i {
      color: ${(props) => props.theme.palette.colors.success} !important;
    }
  
    & li {
      position: relative;
      font-size: 0.92em;
      margin-bottom: 0.65em;
      line-height: 1.35em;
      padding-left: 2em;

      & i {
        font-size: 1.16em;
        vertical-align: text-bottom;
        width: 1.5em;
      }
  
      & > i {
        line-height: 1.16em;
  
        &:first-child {
          position: absolute;
          left: 0;
        }
      }
    }
  }

  /**
  * Hack Elementor styles to fix some specific issues
  */

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: ${(props) => props.theme.grid.maxWidth};
  }

  .elementor-testimonial-image {
    width: 4.8rem !important;
  }

  /*
   * This caused images smaller than content width to get strange aspect ratio and strech because of object-fit: cover

  .inline-gatsby-image-wrapper,
  .inline-gatsby-image-wrapper span {
    display: block !important;
    width: 100% !important;
  }
  */

  /**
  * Set theme specific styles for content within elementor markup
  */

  .elementor {
    .button {
      &.cta {
        color: #fff;
        background-color: ${(props) => props.theme.palette.buttons.ctaAlt};
    
        &:hover {
          color: "fff";
          background-color: ${(props) =>
            lighten(0.1, props.theme.palette.buttons.ctaAlt)};
        }
      }
    }

    & h1 {
      font-size: ${rem(32)};

      ${breakpoint('medium')} {
        font-size: ${rem(42)};
      }

      ${breakpoint('mediumlarge')} {
        font-size: ${rem(50)};
      }

      ${breakpoint('large')} {
        font-size: ${rem(58)};
      }

      ${breakpoint('xlarge')} {
        font-size: ${rem(72)};
      }
    }

    & h2 {
      font-size: ${rem(30)};

      ${breakpoint('medium')} {
        font-size: ${rem(34)};
      }

      ${breakpoint('mediumlarge')} {
        font-size: ${rem(45)};
      }

      ${breakpoint('large')} {
        font-size: ${rem(49)};
      }

      ${breakpoint('xlarge')} {
        font-size: ${rem(54)};
      }
    }

    & h3 {
      font-size: ${rem(22)};

      ${breakpoint('medium')} {
        font-size: ${rem(30)};
      }

      ${breakpoint('mediumlarge')} {
        font-size: ${rem(32)};
      }

      ${breakpoint('large')} {
        font-size: ${rem(35)};
      }

      ${breakpoint('xlarge')} {
        font-size: ${rem(38)};
      }
    }

    & h4 {
      font-size: ${rem(19)};

      ${breakpoint('medium')} {
        font-size: ${rem(21)};
      }

      ${breakpoint('mediumlarge')} {
        font-size: ${rem(22)};
      }

      ${breakpoint('large')} {
        font-size: ${rem(26)};
      }

      ${breakpoint('xlarge')} {
        font-size: ${rem(28)};
      }
    }

    & h5 {
      font-size: ${rem(18)};

      ${breakpoint('medium')} {
        font-size: ${rem(20)};
      }

      ${breakpoint('mediumlarge')} {
        font-size: ${rem(21)};
      }

      ${breakpoint('large')} {
        font-size: ${rem(21)};
      }

      ${breakpoint('xlarge')} {
        font-size: ${rem(23)};
      }
    }

    & h6 {
      font-size: ${rem(17)};

      ${breakpoint('medium')} {
        font-size: ${rem(18)};
      }

      ${breakpoint('mediumlarge')} {
        font-size: ${rem(19)};
      }

      ${breakpoint('large')} {
        font-size: ${rem(20)};
      }

      ${breakpoint('xlarge')} {
        font-size: ${rem(21)};
      }
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-family: ${(props) => props.theme.typography.fonts.alt};
      font-weight: ${(props) => props.theme.typography.weights.alt.bold};
      line-height: 1.23;
      margin-bottom: 1.1em;
    }

    & h4,
    & h5,
    & h6 {
      font-weight: ${(props) => props.theme.typography.weights.alt.normal};
    }
  }
`;
