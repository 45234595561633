import React from 'react';

import TopLayout from './components/TopLayout';
import theme from './theme';

import './typography.css';

// eslint-disable-next-line react/prop-types
export default function wrapWithTheme({ element }) {
  return <TopLayout theme={theme}>{element}</TopLayout>;
}
